
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { useViewPort } from './composables/useViewPort'
import { useStore } from './store'
import AppLoader from '@/components/app-loader.vue'
import AssetsLoader from '@/components/assets-loader.vue'
import { EventBuss } from './types/global'
import { AppMutation } from './store/types'
import screenfull from 'screenfull'
import {
    getDeviceOrientation,
    isIosMobile,
    isMobile as isAMobile
} from './utils'

import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from './types/MessageBox'
import {
    MsgBoxAvatar,
    MsgBoxSetting,
    MsgBoxMessage1,
    MsgBoxPassword,
    MsgBoxHistory,
    MsgBoxRule
} from '@/components/dialog'
import { AssetsManager } from './utils/preloadAssets'
import { useRoute, useRouter } from 'vue-router'
import audioPlayer from './utils/sounds'
import { ROUTES } from './router'

export default defineComponent({
    components: {
        'app-loader': AppLoader,
        'msg-box-avatar': MsgBoxAvatar,
        'msg-box-setting': MsgBoxSetting,
        'msg-box-message': MsgBoxMessage1,
        'msg-box-password': MsgBoxPassword,
        'msg-box-history': MsgBoxHistory,
        'msg-box-rule': MsgBoxRule
    },
    setup() {
        const appRoot = ref<HTMLElement | undefined>(undefined)
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const route = useRoute()
        const router = useRouter()

        const showMainView = ref(true)

        const toastMessage = ref<string>('This is Toast')
        const toastMessageCounter = ref<number>(0)

        const dialogCollection = ref<string[]>([])

        const showLanguageDialogBox = ref<boolean>(false)
        const showSettingDialogBox = ref<boolean>(false)
        const showSecurityDialogBox = ref<boolean>(false)
        const showAgreeDialogBox = ref<boolean>(false)
        const showChipsDialogBox = ref<boolean>(false)
        const showRuleDialogBox = ref<boolean>(false)
        const showRecordDialogBox = ref<boolean>(false)
        const showMessageDialogBox = ref<boolean>(false)
        const showMessageDialogTipBox = ref<boolean>(false)
        const showMessageDialogTipBoxConcent = ref<boolean>(false)
        const showMessageDialogVipBox = ref<boolean>(false)
        const showMessageDialogMediaSettingBox = ref<boolean>(false)
        const showGoodWaySetting = ref(false)
        const showAvatarDialog = ref(false)
        const showWitchPlayMsgBox = ref(false)
        const showCancelConfirmMessage = ref(false)
        const showuserInfoAndBetRecord = ref(false)
        const showChangePasswordMsg = ref(false)
        let clientTimeOutStarted = false
        let clientTimeOutSession: number | boolean = false

        let toastMessageInterval: boolean | number = false

        const appAssets = ref<{
            isLoaded: boolean
            loadCount: number
            assetCount: number
        }>({ isLoaded: false, loadCount: 0, assetCount: 0 })

        //#region Computed Helpers
        const isMobile = computed((): boolean => store.getters['isMobile'])
        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )
        //#endregion

        const { windowResize } = useViewPort(appRoot.value)

        //#region Vue Helpers
        onBeforeMount(() => {
            new AssetsManager(appAssets.value).Preload()

            if (!isIosMobile()) {
                window.addEventListener('resize', handleResizeWindow)
            }

            emitter.on(EventBuss.TOAST_MESSAGE, handleToastMessage)
            emitter.on(EventBuss.DIALOG, handleDialogEvent)
            emitter.on(EventBuss.REQUEST_FULL_SCREEN, requestFullScreen)
            emitter.on(EventBuss.SESSION_EXPIRED, sessionExpired)

            if (isMobile.value) {
                window.addEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }

            // if (token.value && !_ws.isConnected) connectToWebsocket()
        })

        onMounted(() => {
            handleResizeWindow()
            if (!audioPlayer.isLoaded) {
                audioPlayer.isOn = canPlaySound.value ? 1 : 0
                audioPlayer.LoadSounds()
            }
        })

        onBeforeUnmount(() => {
            if (!isIosMobile()) {
                window.removeEventListener('resize', handleResizeWindow)
            }

            if (isMobile.value) {
                window.removeEventListener(
                    'orientationchange',
                    handleOrientationChange
                )
            }
        })
        //#endregion

        //#region Helpers

        const handleResizeWindow = () => {
            windowResize(appRoot.value)
            emitter.emit(EventBuss.WINDOW_RESIZED)
        }

        const handleToastMessage = (_message: string) => {
            toastMessageCounter.value = 9
            toastMessage.value = _message
            if (typeof toastMessageInterval !== 'number') {
                toastMessageInterval = setInterval(() => {
                    toastMessageCounter.value -= 1
                    if (
                        toastMessageCounter.value <= 0 &&
                        typeof toastMessageInterval === 'number'
                    ) {
                        clearInterval(toastMessageInterval)
                        toastMessageInterval = false
                        toastMessage.value = ''
                    }
                }, 150)
            }
        }

        const handleOrientationChange = () => {
            if (screenfull.isEnabled && !screenfull.isFullscreen) {
                const docEl = document.getElementById('app') as HTMLElement
                if (getDeviceOrientation() === 'landscape') {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                }
            }

            if (isAMobile()) {
                showMainView.value = false
                setTimeout(() => {
                    handleResizeWindow()
                    showMainView.value = true
                }, 500)
            }
        }

        const requestFullScreen = () => {
            if (screenfull.isEnabled) {
                const docEl = document.getElementById('app') as HTMLElement
                if (!screenfull.isFullscreen) {
                    screenfull
                        .request(docEl, { navigationUI: 'hide' })
                        .then(() => {
                            store.commit(
                                AppMutation.SET_FULL_SCREEN,
                                screenfull.isFullscreen
                            )
                        })
                } else {
                    screenfull.exit().then(() => {
                        store.commit(
                            AppMutation.SET_FULL_SCREEN,
                            screenfull.isFullscreen
                        )
                    })
                }
            }
        }

        const handleDialogEvent = (e: MessageBoxEvent) => {
            if (e.type === MessageBoxActionType.close) {
                if (dialogCollection.value.includes(e.name)) {
                    const _i = dialogCollection.value.indexOf(e.name)
                    dialogCollection.value.splice(_i, 1)
                }
                if (e.name === 'avatar') showAvatarDialog.value = false
                else if (e.name === 'setting')
                    showSettingDialogBox.value = false
                else if (e.name === 'confirm-cancel-message')
                    showCancelConfirmMessage.value = false
                else if (e.name === 'password')
                    showChangePasswordMsg.value = false
                else if (e.name === 'record') showRecordDialogBox.value = false
                else if (e.name === 'rule') showRuleDialogBox.value = false
            } else if (e.type === MessageBoxActionType.open) {
                if (!dialogCollection.value.includes(e.name))
                    dialogCollection.value.push(e.name)

                if (e.name === 'avatar') showAvatarDialog.value = true
                else if (e.name === 'setting') showSettingDialogBox.value = true
                else if (e.name === 'confirm-cancel-message')
                    showCancelConfirmMessage.value = true
                else if (e.name === 'password')
                    showChangePasswordMsg.value = true
                else if (e.name === 'record') showRecordDialogBox.value = true
                else if (e.name === 'rule') showRuleDialogBox.value = true
            }
        }

        const closeAllDialogs = () => {
            const _arr = [...dialogCollection.value]
            _arr.forEach((_m) => {
                const _ev: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: _m as DialogBoxName
                }

                handleDialogEvent(_ev)
            })
        }

        const initSound = () => {
            if (!isUserInteracted.value) {
                store.commit(AppMutation.SET_USER_INTERACTION)
                audioPlayer.Start()
                audioPlayer.isOn = canPlaySound.value ? 1 : 0

                audioPlayer.soundEffect = musicCanPlay.value

                audioPlayer.SetMusicVolume(musicVolume.value)
                audioPlayer.soundsVolume = soundVoulume.value
            }
        }

        const sessionExpired = () => {
            console.log('session expired')
            emitter.emit(EventBuss.TOAST_MESSAGE, '会话已过期')
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.LOGIN })
        }
        //#endregion

        const showLoading = computed(
            (): boolean => store.getters['showLoading']
        )

        const canPlaySound = computed(
            (): boolean => store.getters['canPlaySound']
        )
        const isUserInteracted = computed(
            (): boolean => store.getters['isUserInteracted']
        )

        const hasVisibleDialog = computed(
            () => dialogCollection.value.length > 0
        )

        const musicVolume = computed((): number => store.getters['musicVolume'])
        const soundVoulume = computed(
            (): number => store.getters['soundVolume']
        )

        const musicCanPlay = computed(
            (): number => store.getters['musicCanPlay']
        )

        watch(
            () => route.name,
            (_name) => {
                if (typeof _name === 'string' && _name === ROUTES.LOGIN) {
                    closeAllDialogs()
                }
            }
        )

        return {
            isMobile,
            appRoot,
            showLoading,
            toastMessage,
            toastMessageCounter,
            hasVisibleDialog,
            showLanguageDialogBox,
            showSettingDialogBox,
            showSecurityDialogBox,
            showAgreeDialogBox,
            showChipsDialogBox,
            showRuleDialogBox,
            showRecordDialogBox,
            showMessageDialogBox,
            showMessageDialogTipBox,
            showMessageDialogTipBoxConcent,
            showMessageDialogVipBox,
            showMessageDialogMediaSettingBox,
            showGoodWaySetting,
            showAvatarDialog,
            showWitchPlayMsgBox,
            showCancelConfirmMessage,
            showuserInfoAndBetRecord,
            appAssets,
            showChangePasswordMsg,
            showMainView,
            initSound,

            requestFullScreen
        }
    }
})
