
import {
    ChipItem,
    Emitter,
    PropType,
    SmallToast,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import { abbreviateNumber } from '@/utils'
import { SVGRmb, SVGRmb2 } from '@/assets/svg'
import { useStore } from '@/store'
import Map from '@/components/fantan-map/index.vue'

import VideoLoader from '@/components/video-loader.vue'

import { ACT, EventBuss, GameRoundState, OtherApiParams } from '@/types/global'
import { RoadMapData } from '@/components/fantan-map/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/fantan-map/utils'
import Betgroup from '@/components/bet/bet-group.vue'
import SwitchTable from '@/components/fantan/switch-table.vue'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { AppActions, AppMutation } from '@/store/types'
import { AxiosSource, fetch } from '@/api'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { Desk } from '@/types/Desk'
import { Dictionary } from '@/types/dictionary'
import { GameResult } from '@/types/GameResult'
import { getValueByKey } from '@/utils/api'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import CountDownTimer from '@/components/timer-count-down.vue'
import ChipControl from '@/components/custom-controls/chips.vue'

export default defineComponent({
    props: {
        deskNo: {
            default: -1,
            type: Number as PropType<number>
        }
    },
    components: {
        'svg-coin-2': SVGRmb2,
        // 'svg-coin': SVGRmb,
        'r-map': Map,
        'bet-group': Betgroup,
        'switch-table': SwitchTable,
        'timer': CountDownTimer,
        'chips': ChipControl,
        'video-loader': VideoLoader
    },
    setup(props) {
        const router = useRouter()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        //xxx=0&result=xzno#参数错误#

        const store = useStore()
        const betGroup = ref()
        const mapData = ref<RoadMapData>(parseRoadMap(''))

        const isFullMapView = ref(false)
        const isOddEvenView = ref(false)
        const isFullVideo = ref(false)

        const showControls = ref(false)
        const showSwitchTable = ref(false)

        const mapKey = ref(new Date().getTime())
        const deskProps = ref({ desk: -1 })
        const desk = ref<Desk | undefined>(undefined)
        let currentGameId: string | undefined = undefined
        let needRefreshGameResult = true
        let currentGameResult: GameResult | undefined = undefined
        const currentCount = ref<number>(-1)
        let needReloadUI = true
        let countDownInterval: number | boolean = false
        const allowBet = ref<boolean>(false)
        const tempTotalBet = ref<number>(0)

        const hasTempBet = ref(false)

        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        const videoState = ref(1)
        const isLoadingVideo = ref<boolean>(true)

        const PULL_INTERVAL = 2000
        const PULL_CARD_INTERVAL = 1500
        let serviceTimeOut: boolean | number = false
        let resultRequestTimeout: boolean | number = false
        let failureCounter = 0
        const funtanResult = ref<string[]>([])

        const toastMessage = ref<SmallToast[]>([])

        //#region Vue Helpers

        onBeforeMount(() => {
            if (props.deskNo <= 0) {
                // toast invalid table
                emitter.emit(EventBuss.TOAST_MESSAGE, '无效表')
                // go back to loby
                router.push({ name: ROUTES.LOBY })
            }

            deskProps.value = { desk: props.deskNo }

            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
            emitter.on(EventBuss.SMALL_TOAST, handleSmallToastMessage)
        })
        onMounted(() => {
            removeAllToast()
            // start pulling data from the server
            loadData()
            initVideoPlayer()
        })

        onBeforeUnmount(() => {
            removeAllToast()
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
            stopPullingData()
            stopCountDown()
            disposeVideoPlayer()
        })
        //#endregion

        const loadData = (_forceReload = false) => {
            if (deskProps.value.desk === -1) {
                // toast invalid table
                emitter.emit(EventBuss.TOAST_MESSAGE, '无效表')
                // go back to loby
                router.push({ name: ROUTES.LOBY })
                return
            }

            const _param = {
                ...OtherApiParams,
                act: ACT.SINGLE_DESK_INFO,
                desk: deskProps.value.desk,
                username: user.value,
                sessionID: token.value,
                xian: xian.value.Id
            }

            if (!_forceReload) {
                store.commit(AppMutation.SHOW_LOADING)
            }

            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((response) => {
                    failureCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    if (response.includes('err=')) {
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                        return
                    } else {
                        const _deskValue = Desk.Parse(
                            response.split('&postionId')[0]
                        )
                        let urlParams = new URLSearchParams(
                            window.location.search
                        )

                        if (urlParams.has('deskNo')) {
                            const _desk = parseInt(
                                urlParams.get('deskNo') || ''
                            )

                            if (_desk === _param.desk) {
                                desk.value = _deskValue

                                if (deskNotice.value !== desk.value.notice) {
                                    // update desk notice
                                    store.commit(
                                        AppMutation.SET_DESK_NOTICE,
                                        desk.value.notice
                                    )
                                }

                                store.commit(
                                    AppMutation.SET_COIN,
                                    desk.value.balance
                                )

                                if (
                                    desk.value.currentTime < 0 &&
                                    _deskValue.status !== 0
                                ) {
                                    resultRequestTimeout = setTimeout(() => {
                                        OnrecievedDeskResult()
                                    }, PULL_CARD_INTERVAL)
                                } else {
                                    serviceTimeOut = setTimeout(() => {
                                        loadData(true)
                                    }, PULL_INTERVAL)
                                }
                            }
                        }
                    }
                })
                .catch(() => {
                    failureCounter += 1
                    if (!_forceReload) {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                    }

                    if (failureCounter > 3) {
                        stopPullingData()
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                        return
                    }

                    serviceTimeOut = setTimeout(() => {
                        loadData(true)
                    }, PULL_INTERVAL)
                })
        }

        const OnrecievedDeskResult = () => {
            if (
                currentGameId === undefined ||
                resultCache.value.ContainsKey(currentGameId)
            ) {
                if (currentGameId !== desk.value?.gameId) {
                    currentGameId = desk.value?.gameId
                }
            }

            if ((desk.value?.currentTime as number) <= 0) {
                needRefreshGameResult = true
            }

            if (
                needRefreshGameResult &&
                currentGameId !== undefined &&
                !resultCache.value.ContainsKey(currentGameId) &&
                currentCount.value <= 0
            ) {
                // get the card

                const _params = {
                    ...OtherApiParams,
                    act: ACT.FUNTAN_RESULT_INFO,
                    username: user.value,
                    gameId: currentGameId
                }

                store
                    .dispatch(AppActions.FETCH_DATA, _params)
                    .then((_data: string) => {
                        const err = getValueByKey(_data, 'err')
                        if (err) {
                            emitter.emit(EventBuss.SESSION_EXPIRED)
                            return
                        } else {
                            if (currentGameResult !== undefined) {
                                if (currentGameResult.rawData === _data) {
                                    resultRequestTimeout = setTimeout(() => {
                                        OnrecievedDeskResult()
                                    }, PULL_CARD_INTERVAL)
                                    return
                                }
                            }

                            if (
                                currentGameId !== undefined &&
                                resultCache.value.ContainsKey(currentGameId)
                            ) {
                                resultRequestTimeout = setTimeout(() => {
                                    OnrecievedDeskResult()
                                }, PULL_CARD_INTERVAL)
                                return
                            }

                            currentGameResult = new GameResult(
                                _data,
                                (desk.value as Desk).gameType
                            )

                            if (
                                currentGameResult.state ===
                                    GameRoundState.END_OF_ROUND &&
                                !currentGameResult.isNewShoe
                            ) {
                                store.commit(AppMutation.ADD_RESULT_TO_CACHE, {
                                    key: currentGameId,
                                    value: currentGameResult.rawData
                                })

                                needRefreshGameResult = true

                                // winOrLostAmount.value = currentGameResult.won
                                const _winOrLostAmount = currentGameResult.won

                                if (_winOrLostAmount !== 0) {
                                    const _m: SmallToast = {
                                        id: props.deskNo,
                                        message:
                                            _winOrLostAmount > 0
                                                ? `+${_winOrLostAmount}`
                                                : `${_winOrLostAmount}`,
                                        color:
                                            _winOrLostAmount > 0
                                                ? 'green'
                                                : 'red'
                                    }
                                    emitter.emit(EventBuss.SMALL_TOAST, _m)
                                }

                                showControls.value = true

                                funtanResult.value =
                                    currentGameResult.funtanResult
                                setTimeout(() => {
                                    funtanResult.value = []
                                    showControls.value = false
                                }, 5000)

                                const { whoWin } = currentGameResult

                                if (whoWin === 1) {
                                    audioPlayer.Play(SOUNDKEY.FANTAN_1)
                                } else if (whoWin === 2) {
                                    audioPlayer.Play(SOUNDKEY.FANTAN_2)
                                } else if (whoWin === 3) {
                                    audioPlayer.Play(SOUNDKEY.FANTAN_3)
                                } else if (whoWin === 4) {
                                    audioPlayer.Play(SOUNDKEY.FANTAN_4)
                                }

                                stopPullingData()
                                loadData(true)
                            } else {
                                resultRequestTimeout = setTimeout(() => {
                                    OnrecievedDeskResult()
                                }, PULL_CARD_INTERVAL)
                            }
                        }
                    })
                    .catch((e: any) => {
                        failureCounter += 1

                        if (failureCounter > 3) {
                            stopPullingData()
                            emitter.emit(EventBuss.TOAST_MESSAGE, e)
                            store.commit(AppMutation.CLEAR_ALL)
                            router.push({ name: ROUTES.LOGIN })
                            return
                        }
                        resultRequestTimeout = setTimeout(() => {
                            OnrecievedDeskResult()
                        }, PULL_CARD_INTERVAL)
                    })
            } else {
                stopPullingData()
                loadData(true)
            }
        }

        const stopPullingData = () => {
            if (AxiosSource) {
                // cancell all axios pending request
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }

            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }

            if (typeof resultRequestTimeout === 'number') {
                clearTimeout(resultRequestTimeout)
                resultRequestTimeout = false
            }
        }

        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                showControls.value = true
                allowBet.value = true
                tempTotalBet.value = 0
                currentCount.value = desk.value?.currentTime ?? 0
                if (currentCount.value > 20) {
                    audioPlayer.Play(SOUNDKEY.START_BET)
                }

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                        audioPlayer.Play(SOUNDKEY.STOP_BET)
                    } else {
                        if (
                            currentCount.value <= 11 &&
                            currentCount.value > 0
                        ) {
                            audioPlayer.Play(SOUNDKEY.TICK)
                        }
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const stopCountDown = () => {
            if (typeof countDownInterval === 'number') {
                showControls.value = false
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
                allowBet.value = false

                // here reset the bet view
                if (typeof betGroup.value !== 'undefined') {
                    const view = betGroup.value as any
                    if (view) view.clearBet()
                }
            }
        }

        const showDialog = (e: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: e
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const handleOnWindowResize = () => {
            mapKey.value = new Date().getTime()
        }
        const hideSwitchTable = (e: MouseEvent) => {
            showSwitchTable.value = false
        }

        const toLoby = () => {
            router.push({ name: ROUTES.LOBY })
        }

        const handleTouchStartEvent = (e: TouchEvent) => {
            e.preventDefault()
        }

        const handleOnMouseUp = () => {
            emitter.emit(EventBuss.BET_MOUSE_UP)
        }

        const OnMapDataChange = (data: RoadMapData) => {
            mapData.value = data
            // console.log(mapData.value)
        }

        const onTableChange = (table: Desk) => {
            showSwitchTable.value = false

            if (props.deskNo === table.tableNo) return

            deskProps.value = { desk: table.tableNo }

            router.replace({
                query: { deskNo: deskProps.value.desk }
            })

            stopPullingData()
            stopCountDown()
            needReloadUI = true
            isFullVideo.value = false

            currentGameId = undefined
            funtanResult.value = []
            clearBet()
            loadData()

            initVideoPlayer()
        }

        //#region Video Player Helpers
        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()

            if (!video.value) return

            const videoPaths = [process.env.VIDEO_URL1, process.env.VIDEO_URL2]

            let isVideoConnected = false

            videoPaths.forEach((vPath) => {
                let videoUrl = ''

                if (isFullVideo.value) {
                    // videoUrl = `${vPath}/ft/ftvideo3.flv`
                    // http://ff2.66286662.net:8000/ft2/ftvideo3.flv

                    videoUrl = `${vPath}/ft${deskProps.value.desk}/ftvideo3.flv`
                } else {
                    if (videoState.value === 1)
                        videoUrl = `${vPath}/ft${deskProps.value.desk}/ftvideo1.flv`
                    else
                        videoUrl = `${vPath}/ft${deskProps.value.desk}/ftvideo2.flv`
                }

                // eslint-disable-next-line no-undef
                const _player = new NodePlayer()
                _player.setVolume(0)
                _player.setView('videoplayer2')
                _player.setBufferTime(1000)
                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        nodePlayer.clearView()
                        nodePlayer.setView('videoplayer1')
                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                }
                            }
                        )

                        nodePlayer.on('error', () => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.clearView()
                                nodePlayer.stop()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.clearView()
                        _player.stop()
                    }
                })
                _player.on('error', () => {
                    //
                })

                videoPlayerCollection.push(_player)
                _player.start(videoUrl)
            })
        }

        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.clearView()
                    _player.stop()
                }
            })

            videoPlayerCollection = []
        }

        const toggleVideoState = () => {
            isFullVideo.value = false
            videoState.value = videoState.value === 1 ? 2 : 1
        }

        const fullVideoView = () => {
            if (!isFullVideo.value) {
                isFullVideo.value = true

                initVideoPlayer()
            }
        }
        //#endregion

        //#region  Helpers
        const onBetChange = (sum: number) => {
            hasTempBet.value = sum > 0
        }

        const clearBet = () => {
            if (betGroup.value) betGroup.value.clearBet()
        }
        const confirmBet = () => {
            if (betGroup.value) {
                betGroup.value.confirmBet()
            }
        }

        const handleSmallToastMessage = (e: SmallToast) => {
            if (deskProps.value.desk !== -1 && deskProps.value.desk === e.id) {
                const _t = {
                    id: setTimeout(() => {
                        const _tt = toastMessage.value.shift()
                        if (_tt && typeof _tt.id === 'number')
                            clearTimeout(_tt.id)
                    }, 1500),
                    message: e.message,
                    color: e.color
                }
                toastMessage.value.push(_t)
            }
        }

        const removeAllToast = () => {
            toastMessage.value.forEach((e) => {
                if (typeof e.id === 'number') {
                    clearTimeout(e.id)
                    e.id = false
                }
            })
            toastMessage.value = []
        }

        //#endregion

        //#region Computed
        const deskResult = computed((): string => {
            if (desk.value) return desk.value.result
            return ''
        })

        const chips = computed((): number[] => store.getters['chips'])

        const user = computed(() => store.getters['user'])
        const coin = computed(() => store.getters['coin'])
        const token = computed(() => store.getters['token'])
        const deskNotice = computed((): string => store.getters['deskNotice'])
        const avatar = computed(() => store.getters['avatar'])
        const video = computed(() => store.getters['videoIsOn'])

        const customChip = computed(() => store.getters['customChip'])
        const currentChipIndex = computed(
            (): number => store.getters['currentChip']
        )
        const chipValue = computed(() => {
            let _val = 0

            if (chips.value.length < 5) return _val

            if (currentChipIndex.value === 22) return customChip.value

            return chips.value[currentChipIndex.value]
        })

        const resultCache = computed(
            (): Dictionary<string> => store.getters['resultCache']
        )

        const xian = computed(() => store.getters['xian'])

        const pLimit = computed(() => {
            if (desk.value) return desk.value.plimit
            return '0-0'
        })

        const aLimit = computed(() => {
            if (desk.value) return desk.value.alimit
            return '0-0'
        })

        const sums = computed(() => {
            if (desk.value) return desk.value.sums
            return 0
        })

        const sumd = computed(() => {
            if (desk.value) return desk.value.sumd
            return 0
        })

        const sum1 = computed(() => {
            if (desk.value) return desk.value.sum1
            return 0
        })

        const sum2 = computed(() => {
            if (desk.value) return desk.value.sum2
            return 0
        })
        const sum3 = computed(() => {
            if (desk.value) return desk.value.sum3
            return 0
        })

        const sum4 = computed(() => {
            if (desk.value) return desk.value.sum4
            return 0
        })

        const allSums = computed(() => {
            if (desk.value) return desk.value.allsums
            return 0
        })

        const allSumd = computed(() => {
            if (desk.value) return desk.value.allsumd
            return 0
        })

        const allsum1 = computed(() => {
            if (desk.value) return desk.value.allsum1
            return 0
        })

        const allsum2 = computed(() => {
            if (desk.value) return desk.value.allsum2
            return 0
        })
        const allsum3 = computed(() => {
            if (desk.value) return desk.value.allsum3
            return 0
        })

        const allsum4 = computed(() => {
            if (desk.value) return desk.value.allsum4
            return 0
        })

        //#endregion

        //#region Watchers

        watch(
            () => video.value,
            (_v) => {
                setTimeout(() => {
                    initVideoPlayer()
                }, 100)

                if (!_v) {
                    disposeVideoPlayer()
                }
            }
        )

        watch(
            () => desk.value,
            (_val) => {
                if (_val && needReloadUI) {
                    // reloadUI()
                    if (_val.currentTime !== -1) {
                        startCountDown()
                    } else {
                        if (typeof countDownInterval === 'number') {
                            stopCountDown()

                            audioPlayer.Play(SOUNDKEY.STOP_BET)
                        }
                        currentCount.value = -1
                    }
                } else {
                    needReloadUI = true
                }
            },
            { immediate: true }
        )

        watch(
            () => videoState.value,
            () => {
                initVideoPlayer()
            }
        )

        //#endregion
        return {
            allSumd,
            allSums,
            allsum1,
            allsum2,
            allsum3,
            allsum4,
            sum1,
            sum2,
            sum3,
            sum4,
            sumd,
            sums,
            pLimit,
            aLimit,
            video,
            desk,
            mapKey,
            chipValue,
            chips,
            isFullMapView,
            user,
            coin,
            avatar,
            deskResult,
            abbreviateNumber,
            handleOnMouseUp,
            isOddEvenView,
            OnMapDataChange,
            mapData,
            handleTouchStartEvent,
            showControls,
            showSwitchTable,
            currentCount,
            betGroup,
            clearBet,
            confirmBet,
            hideSwitchTable,
            toLoby,
            showDialog,
            onTableChange,
            funtanResult,
            allowBet,
            deskProps,
            toastMessage,
            hasTempBet,
            onBetChange,
            isLoadingVideo,
            videoState,
            toggleVideoState,
            fullVideoView
        }
    }
})
